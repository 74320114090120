.droparea-div {
	overflow: hidden;
	position: relative;
	display: block;
	width: 25rem;
	height: 30rem;
	border: .2rem dashed $grey;
	border-radius: .6rem;
	text-align: center;
	cursor: pointer;
	@include transition-property(border-color, background-color);
	@include transition-duration(.3s);
	@include transition-timing-function(ease-in-out);
	padding-top: 9.1rem;
	#droparea {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 24.7rem;
		opacity: 0;
	}
	input { cursor: pointer; }
	&:hover {
		background: rgba(51,122,222,.1);
		border-color: $blue;
		use {fill: $blue}
	}

	// NORMAL STATE
	&-state-normal span {
		@extend .typo-ui-txt-small;
		color: $grey;
		width: 11.5rem;
		display: block;
		margin: 1.2rem auto;
		line-height: 1.6rem;
		p { color: $blue; }
	}
	&-state-dragover {
		span {
			@extend .typo-ui-txt-small;
			display: block;
			margin: 0 auto;
			width: 13.3rem;
			line-height: 1.6rem;
		}
	}

	&-state-dragover,
	&-state-uploading,
	&-state-uploaded-hover { display: none; }

	// DRAG STATE
	&.drag {
		padding-top: 13.4rem;
		border-color: $blue;
		background-color: transparentize($blue, .9);

		.droparea-div-state-normal,
		.droparea-div-state-uploading,
		.droparea-div-state-uploaded-hover { display: none; }
		.droparea-div-state-dragover { display: block; }
	}

	&.uploading {
		padding-top: 11.2rem;
		border-color: $blue;
		background-color: transparentize($blue, .9);
		.droparea-div-state-normal,
		.droparea-div-state-dragover,
		.droparea-div-state-uploaded-hover { display: none; }
		.droparea-div-state-uploading {
			display: block;
			@include square-size(7rem);
			margin: 0 auto;
		}
	}

	&.uploaded {
		.droparea-div-state-normal,
		.droparea-div-state-uploading,
		.droparea-div-state-dragover { display: none; }
		.droparea-div-state-uploaded-hover {
			display: block;
			opacity: 0;
		}
		border: .2rem solid transparent;
		padding: 0;
		.droparea-div-preview {
			position: absolute;
			display: block;
			@include square-size(100%);
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}
		&:hover, &.hover {
			border: 2px dashed $blue;
			padding: 0;
			.droparea-div-state-uploaded-hover {
				padding-top: 12.3rem;
				position: absolute;
				display: block;
				opacity: 1;
				width: 100%;
				height: 100%;
				background-color: transparentize($blue, .7);
				@include transition(opacity .3s ease)
				span {
					width: 13.6rem;
					display: block;
					margin: 0 auto;
					@extend .typo-ui-txt-small;
					line-height: 1.6rem;
					p { color: $blue; }
				}
			}
		}
	}
}

// PROGRESS BAR STYLES
#circleloader {
	width: 70px;
	height: 70px;
}
.progress_text {
	font-family: $pt-sans;
	font-weight: bold;
	font-size: 1.4rem;
}
.col-3 .droparea-div.uploaded .droparea-div-preview {
	background-image: url('/img/drop.jpg');
	// дэмо-фотография для спецификации
}
