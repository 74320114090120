	body.no-scroll { overflow: hidden; }

.dialog_wrapper {
	position: fixed;
	display: block;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 12221;
	opacity: 1;
	@include transition(opacity .2s ease-in-out);

	.dialog_overlay {
		position: fixed;
		display: block;
		background-color: transparentize($dark, .15);
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 12222;
	}
	.dialog_box {
		position: fixed;
		display: block;
		text-align: center;
		width: 350px;
		height: auto;
		top: auto;
		bottom: auto;
		left: 0;
		right: 0;
		background: $light;
		padding: 30px 30px 36px;
		border-radius: 3px;
		margin: auto;
		opacity: 1;
		z-index: 12223;
		top: 50%;
		transform: translateY(-50%);
		@include transition(opacity .2s ease);
		h3 {
			@extend .typo-ui-h3, .dark;
			margin: 0 auto;
		}
		p {
			@extend .typo-ui-txt, .grey;
			margin: 12px 0 0;
		}
		.dialog_box_btn_wrapper {
			margin-top: 24px;
			a { @extend .button, .button-grey, .button-middle; }
		}
		&.warn{
			.dialog_box_btn_wrapper .dialog_box_confirm { @extend .button-pink; }
		}
		&.confirm{
			.dialog_box_btn_wrapper .dialog_box_confirm { @extend .button-blue; }
		}
		&.warn .dialog_box_btn_wrapper a, &.confirm .dialog_box_btn_wrapper a { margin: 0 3px; }
	}
	&.hidden { opacity: 0; }
}





// .in_dev {
// 	position: fixed;
// 	display: block;
// 	text-align: center;
// 	width: 350px;
// 	height: 142px;
// 	top: 0;
// 	bottom: 0;
// 	left: 0;
// 	right: 0;
// 	background: $light;
// 	padding: 0 30px;
// 	border-radius: 3px;
// 	margin: auto;
// 	opacity: 1;
// 	z-index: 12223;
// 	@include transition(opacity .2s ease);
// 	h3 {
// 		@extend .typo-ui-h3, .dark;
// 		margin: 30px auto 24px;
// 	}
// 	a { @extend .button, .button-grey, .button-middle; }
// 	&.hidden {
// 		opacity: 0;
// 		right: -500%;
// 		@include transition(none);
// 	}
// 	&_overlay {
// 		position: fixed;
// 		display: block;
// 		background-color: transparentize($dark, .15);
// 		top: 0;
// 		left: 0;
// 		bottom: 0;
// 		right: 0;
// 		z-index: 12222;
// 		opacity: 1;
// 		@include transition(opacity .2s ease-in-out);
// 		&.hidden {
// 			opacity: 0;
// 			right: 100%;
// 		}
// 	}
// }
