input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 50000s ease-in-out 0s;
	-webkit-text-fill-color: #fff;
}

.reg-login {
	height: 100%;
	width: 100%;
	background: url(../img/login_bg.jpg);
	background-size: cover;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10001;
	opacity: 1;
	transition: opacity 0.4s;
	overflow: hidden;

	&.hidden {
		opacity: 0;
		pointer-events: none;
	}
}

.close-reg-login {
	position: absolute;
	top: 60px;
	right: 60px;
	cursor: pointer;

	&.mobile {
		display: none;
		top: 18px;
		right: 18px;
	}
}

.transparent {
	height: 100%;
	width: 100%;
	background-color: $dark;
	opacity: .9;
	position: absolute;
	top: 0;
	left: 0;
}

.reg-login-wrap {
	height: 100%;
	width: 100%;
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	text-align: center;

	form {
		width: 351px;
		margin: 0 auto;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0s;
		position: absolute;
		top: 0;

		&.form-registration {
			.input-form {
				&:last-of-type { margin-bottom: 0; }
			}
		}

		&.active {
			opacity: 1;
			pointer-events: all;
			position: relative;
			transition: opacity 0.6s;
		}

		.form-title { margin-bottom: 36px; }

		.social-links {
			width: 351px;
			margin: 0 auto 36px;
			a {text-decoration: none;}
			a:not(:last-child) { margin-right: 6px; }
			p { margin-bottom: 24px; }
			.i32 {display: none}
		}

		.or {
			margin-bottom: 54px;

			.line {
				height: 2px;
				width: 150px;
				border-bottom: 2px solid $dark-plus;
				display: inline-block;
			}

			p {
				display: inline-block;
				margin: 0 9px;
				position: relative;
				top: 3px;
			}
		}
	}
	.form-forgot {
		width: auto;
		text-align: left;
		.title {
			@extend .typo-ui-h3;
			color: #fff;
			max-width: 399px;
			margin-bottom: 24px;
		}
	}

	.form-success {
		width: auto;
		max-width: 390px;
		text-align: center;

		h3 {
			@extend .typo-ui-h3;
			color: #fff;
			margin-top: 16px;
			margin-bottom: 12px;
		}
		p {
			@extend .typo-ui-txt;
			color: $grey-text;
		}
	}
}

.main-form-reg {
	text-align: left;
	margin-bottom: 30px;

	input.select-checkbox {
		margin-bottom: 0;
	}
	
	.input-form {
		margin-bottom: 44px;
		&.password {margin-bottom: 0}
	}

	.forgot-wrap {
		margin-top: 24px;
		text-align: center;
	}

	.forgot-password {
		text-decoration: none;
		line-height: 20px;
		transition: color 0.2s;

		&:hover { color: $blue; }
	}

	button {
		width: 100%;
		margin-top: 54px;
	}

	.terms-of-use {
		text-align: center;
		line-height: 18px;
		margin-top: 18px;
	}
}

#loading {
	position: fixed;
	display: block;
	top: 0;
	z-index: 99999;
	width: 100%;
	height: 100%;
	background-color: $dark;
	opacity: .70;
	@include transition(opacity .3s ease);
	cursor: default;
	svg {
		position: absolute;
		display: block;
		width: 42px;
		height: 42px;
		top: 50%;
		left: 0;
		right: 0;
		margin: auto;
	}
	&.hidden{
		top: -100%;
		opacity: 0;
	}
}

@media all and (max-width: 1199px) {
	.reg-login-wrap {overflow-y: auto;}
}
@media all and (max-width: 767px) {
	.close-reg-login {
		display: none;

		&.mobile {
			display: block;
			z-index: 5;
		}
	}

	.reg-login-wrap {
		display: block;
		padding: 24px 0;

		form {
			width: 321px;
			padding: 0 10px;

			h1 { margin-bottom: 24px; }

			.social-links {
				width: 302px;
				margin: 0 auto 24px;
				a:not(:last-child) { margin-right: 16px; }
				svg:hover {use{fill:#fff !important;}}
				p { margin-bottom: 18px; }
				.i32 {display: inline-block;}
				.i50 {display: none;}
			}

			.or {
				margin-bottom: 30px;
				.line { width: 125px; }
			}
		}
		.form-forgot,
		.form-success { margin-top: 84px; }
	}

	.main-form-reg {
		margin-bottom: 18px;
		.input-form { margin-bottom: 24px; }
		.forgot-wrap { margin-top: 18px; }
		button { margin-top: 24px; }
	}
}
