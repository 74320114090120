.input {
	-webkit-appearance: none;
	-moz-appearance: none;
	-webkit-tap-highlight-color: transparent;
	height: 3.6rem;
	border: 2px solid $grey;
	border-radius: .3rem;
	background-color: transparent;
	padding: 0 1.2rem;
	margin-bottom: .4rem;
	font-family: $roboto;
	font-size: 1.5rem;
	line-height: 1.5rem;
	color: $grey-text;
	@include transition(all, .3s, ease-in-out);
	&-label {
		text-transform: uppercase;
		display: block;
		margin-bottom: 1.2rem;
	}

	&:hover:not(:disabled):not(:focus):not(.error):not(.success) { border-color: darken($grey, 10%); }
	&:focus { border-color: $blue; }
	&.error {
		border-color: $pink;
		+ .input-hint { color: $pink; }
	}
	&.success {
		border-color: $green;
		background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2212px%22%20height%3D%2210px%22%20viewBox%3D%220%200%2012%2010%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20xmlns%3Asketch%3D%22http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch%2Fns%22%3E%0A%20%20%20%20%3Cg%20id%3D%22Page-1%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20sketch%3Atype%3D%22MSPage%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20id%3D%22Spec-v1_%D0%9A%D0%B0%D0%BB%D1%8C%D0%BC%D0%B0%D1%80%22%20sketch%3Atype%3D%22MSArtboardGroup%22%20transform%3D%22translate%28-891.000000%2C%20-1005.000000%29%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%20id%3D%22Icons%22%20sketch%3Atype%3D%22MSLayerGroup%22%20transform%3D%22translate%280.000000%2C%20901.000000%29%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%20id%3D%22Icn-16-%2F-Base%22%20transform%3D%22translate%28214.000000%2C%2073.000000%29%22%20sketch%3Atype%3D%22MSShapeGroup%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%20id%3D%22Icn-16-%2F-Base-%2F-Check-%2F-Grey%22%20transform%3D%22translate%28675.000000%2C%2028.000000%29%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M12.3%2C3%20L6.1%2C9.5%20L3.7%2C7%20L2%2C8.8%20L6%2C13%20L6.1%2C12.9%20L6.2%2C13%20L14%2C4.8%20L12.3%2C3%20Z%22%20id%3D%22Shape-16%22%20fill%3D%22%234BAE4F%22%3E%3C%2Fpath%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E');
		background-position: calc(100% - 12px) center; // Пока не ясно, где это будет работать, а где - нет
		background-repeat: no-repeat;
		padding: 0 4rem 0 1.2rem;
		+ .input-hint { color: $green; }
	}
	&:disabled {
		background-color: $grey;
		color: $white;
		&::-webkit-input-placeholder { color: $grey-plus; }
	}
	&-textarea {
		resize: none;
		padding-top: .9rem;
	}

	// INPUT SIZES
	&-small { width: 250px; }
	&-medium { width: 380px; }
	&-large { width: 512px; }
	&-xlarge { width: 700px; }

	// TEXTAREA SIZES
	&-textarea-small { height: 5rem; }
	&-textarea-medium { height: 7.8rem; }
	&-textarea-large { height: 10.6rem; }

	&::-webkit-input-placeholder {
		font-family: $roboto;
		font-size: 1.5rem;
		line-height: 1.5rem;
		color: $grey-text;
		padding-top: 2px;
	}
}
.select {
	&-radio {
		@include square-size(1.8rem);
		@include transition(all, .3s, ease-in-out);
		border: .2rem solid $grey;
		border-radius: 50%;
		float: left;
		margin: 0 0 1.2rem 0;
		+ label { color: $grey-text; }
		&:checked {
			border: .5rem solid $blue;
			background-color: $white;
			+ label { color: $dark; }
		}
		&:disabled {
			border: .2rem solid $grey-plus;
			+ label { color: $grey-plus; }
			&:checked {
				border-width: .4rem;
				background-color: $white;
			}
		}
		&:hover:not(:disabled) { cursor: pointer; }
		&:hover:not(:checked):not(:disabled) {
			border-color: darken($grey, 10%);
			+ label { color: darken($grey-text, 10%); }
		}
	}
	&-checkbox {
		@include square-size(1.8rem);
		@include transition-property(border-color, background-color);
		@include transition-duration(.3s);
		@include transition-timing-function(ease-in-out);

		border: .2rem solid $grey;
		border-radius: 2px;
		float: left;
		margin: 0 0 1.2rem 0;
		+ label { color: $grey-text; }

		&.big {
			@include square-size(3rem);
			@include transition-duration(.2s);

			border: .2rem solid $green;
			border-radius: 3px;
			margin: 0;
			&:checked {
				border-color: $green;
				background-color: $green;
			}
			&:hover:not(:checked):not(:disabled):not(.check-light) {
				border-color: darken($green, 10%);
			}
		}

		&:checked {
			border-color: $blue;
			background-color: $blue;
			background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2212px%22%20height%3D%2210px%22%20viewBox%3D%220%200%2012%2010%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20xmlns%3Asketch%3D%22http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch%2Fns%22%3E%0A%20%20%20%20%3Cg%20id%3D%22Page-1%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20sketch%3Atype%3D%22MSPage%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20id%3D%22Spec-v1_%D0%9A%D0%B0%D0%BB%D1%8C%D0%BC%D0%B0%D1%80%22%20sketch%3Atype%3D%22MSArtboardGroup%22%20transform%3D%22translate%28-891.000000%2C%20-1005.000000%29%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%20id%3D%22Icons%22%20sketch%3Atype%3D%22MSLayerGroup%22%20transform%3D%22translate%280.000000%2C%20901.000000%29%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%20id%3D%22Icn-16-%2F-Base%22%20transform%3D%22translate%28214.000000%2C%2073.000000%29%22%20sketch%3Atype%3D%22MSShapeGroup%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%20id%3D%22Icn-16-%2F-Base-%2F-Check-%2F-Grey%22%20transform%3D%22translate%28675.000000%2C%2028.000000%29%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M12.3%2C3%20L6.1%2C9.5%20L3.7%2C7%20L2%2C8.8%20L6%2C13%20L6.1%2C12.9%20L6.2%2C13%20L14%2C4.8%20L12.3%2C3%20Z%22%20id%3D%22Shape-16%22%20fill%3D%22%23FFFFFF%22%3E%3C%2Fpath%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E');
			background-position: center;
			background-repeat: no-repeat;
			+ label { color: $dark; }
		}
		&:disabled {
			border: .2rem solid $grey-plus;
			+ label { color: $grey-plus; }
			&:checked {
				border-color: $grey;
				background-color: $grey;
			}
		}

		&:hover:not(:disabled) { cursor: pointer; }
		&:hover:not(:checked):not(:disabled):not(.check-light) {
			border-color: darken($grey, 10%);
			+ label { color: darken($grey-text, 10%); }
		}

		&.check-light {
			&:not(:disabled):not(:checked) { border-color: $white; }
			+ label { color: $white; }
			&:hover:not(:disabled):not(:checked) {
				border-color: darken($grey, 10%);
				+ label { color: darken($grey-text, 10%); }
			}
			&:disabled {
				+ label { color: $dark-plus; }
			}
		}
	}

	+ label {
		@extend .typo-ui-txt;
		@include transition(all, .3s, ease-in-out);
		float: left;
		line-height: 2rem;
		padding-left: 1.2rem;
		+ br {
			clear: both;
			height: 1.2rem;
		}
	}
	&-light { border-color: $white; }
}

.switch {
	@include transition(all, .3s, ease-out);
	position: relative;
	width: 3rem;
	height: 1.8rem;
	border: 2px solid $grey;
	border-radius: 1.2rem;
	margin: 0 0 1.2rem 0;
	&:before {
		@include transition(all, .3s, ease-out);
		content: '';
		position: absolute;
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
		background-color: $grey;
		top: .2rem;
		left: .2rem;
	}
	&:checked {
		border-color: $extra-green;
		background-color: $extra-green;
		&:before {
			left: 1.4rem;
			background-color: $white;
		}
		+ label { color: $dark; }
	}
	&:hover:not(:disabled) { cursor: pointer; }
	&:hover:not(:disabled):not(:checked) {
		border-color: darken($grey, 10%);
		&:before { background-color: darken($grey, 10%); }
		+ label { color: darken($grey-text, 10%); }
	}

	&:disabled {
			border: .2rem solid $grey-plus;
			+ label { color: $grey-plus; }
			&:checked {
				border-color: $grey;
				background-color: $grey;
			}
			&:not(:checked):before { background-color: $grey; }
		}

	&-left { float: left; }
	&-right {
		float: right;
		+ label {float: left;}
	}

	&.blue {
		&:checked {
			border-color: $blue;
			background-color: $blue;
		}
	}

	+ label {
		@extend .typo-ui-txt;
		@include transition(all, .3s, ease-in-out);
		color: $grey-text;
		line-height: 2rem;
		padding-left: 1.2rem;
		+ br {
			clear: both;
			height: 1.2rem;
		}
	}
}

.input-form {
	position: relative;
	&.dark {
		.input {
			border-color: $grey;
			color: $white;
		}
		.input-description { color: $grey-text; }
	}
	&.error {
		.input {
			&:focus { border-color: $pink; }
			&:hover { border-color: $pink !important; }
			border-color: $pink;
		}
		.input-description {
			height: 16px;
			color: $pink;
		}
	}
	&.disabled{ opacity: .5; }
	&.textarea {
		.input {
			position: relative;
			bottom: -4px;
		}
		.input-description { padding-top: 2px; }
	}
	&.password {
		.input {padding-right: 36px;}
		a {
			height: 24px;
			width: 24px;
			position: absolute;
			top: 14px;
			right: 6px;
			opacity: 1;
			transition: opacity 0.2s;
			cursor: pointer;
			&.hidden {
				opacity: 0;
				pointer-events: none;
			}
			svg {
				position: relative;
				top: 4px;
				left: 4px;
			}
			&.hide-password svg {
				top: 7px;
			}
		}
	}
	&.simple {
		.input {
			&::-webkit-input-placeholder { color: $grey-text !important; }
			&:-moz-placeholder { color: $grey-text !important; }
			&::-moz-placeholder { color: $grey-text !important; }
			&:-ms-input-placeholder { color: $grey-text !important; }
		}
	}
	&.input-button {
		width: 512px !important;
		.input {
			max-width: 399px;
			padding-bottom: 10px;
		}
		button {
			float: right;
			margin-top: -10px;
		}
		.input-description {
			max-width: 399px;
			//height: auto !important;
			//position: relative;
			bottom: -18px;
		}
	}
	.placeholder {
		@extend .typo-ui-txt;
		color: $grey-text;
		position: relative;
		bottom: -14px;
		pointer-events: none;
		text-transform: none;
		transition: all 0.2s;

		&.active {transform: translateY(-14px);}
		&.always_active {transform: translateY(-14px);}
		&.focused {color: $blue;}
	}

	.email {
		margin-top: 45px;
		.placeholder { top: 16px; }
		.input {
			padding-bottom: 0;
			margin-bottom: 6px;
		}
	}

	.input {
		@extend .typo-ui-txt;
		width: 100%;
		height: 26px;
		max-height: 153px;
		padding-left: 0;
		padding-bottom: 3px;
		border-top: 0;
		border-left: 0;
		border-right: 0;
		border-radius: 0;
		border-color: $grey-second;
		color: $dark;
		resize: none;
		-webkit-appearance: none;

		&:focus { border-color: $blue; }
		&:hover:not(:disabled) { border-color: $blue !important; }
		&:disabled { background-color: transparent; }

		&::-webkit-input-placeholder { color: transparent; font-size: 14px; }
		&:-moz-placeholder { color: transparent; font-size: 14px; }
		&::-moz-placeholder { color: transparent; font-size: 14px; }
		&:-ms-input-placeholder { color: transparent; font-size: 14px; }
	}
	.input-description {
		@extend .typo-ui-txt-small;
		color: $dark;
		margin-top: 2px;
		height: 0;
		overflow: hidden;
		transition: height 0.3s;
		cursor: default;
		position: absolute;
		left: 0;
		bottom: -18px;
		display: inline-block;
		&.visible { height: 16px; }
	}
}

@media all and (max-width: 767px) {
	.input-form {
		&.input-button { padding: 0 !important; }
		&.password a {top: 0}
		.placeholder {display: none}
		.input-description {
			position: relative;
			bottom: 0;
		}
		.input {
			&::-webkit-input-placeholder { color: $grey-text; }
			&:-moz-placeholder { color: $grey-text; }
			&::-moz-placeholder { color: $grey-text; }
			&:-ms-input-placeholder { color: $grey-text; }
		}
	}
}

@media all and (max-width: 551px) {
	.input-form {
		&.input-button {
			padding: 10px !important;
			.input { max-width: 100%; }
			button {
				float: none;
				margin-top: 24px;
				width: 100%;
			}
			.input-description { max-width: 100%; }
		}
	}
}
