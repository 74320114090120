@import 'reset';
@import 'fonts';
* {
	box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;
    -webkit-tap-highlight-color:  transparent;
	-moz-appearance: none;
	outline: none; 
}
:root { font-size: 10px; }
body { color: #263238; }
html, body { height: 100%; }


