// COLORS
$dark: #242C2F;
$dark-plus: #999999;
$grey: #999999;
$grey-plus: #CCCCCC;
$grey-second: #DADADA;
$light: #F5F5F5;
$white: #FFFFFF;

$blue: #337ADE;
$green: #00C690;
$yellow: #00C690;
$orange: #FFC000;
$pink: #FE6260;
$purple: #00C690;
$red: #FE6260;

$blue-light: #BAD0EE;
$green-light: #ABE6D6;
$orange-light: #F3DF76;
$pink-light: #F7C8C8;
$purple-light: #ABE6D6;
$red-light: #F7C8C8;

$grey-text: #999999;
$grey-text2: #666666;
$text-second: #999999;

$extra-cyan: #00C690;
$extra-lime: #00C690;
$extra-yellow: #00C690;
$extra-coral: #FE6260;
$extra-green: #00C690;
$extra-blue: #00C690;
$extra-purple: #00C690;

// FONTS
$pt-serif: 'PT Serif', serif;
$pt-sans: 'PT Sans', sans-serif;
$roboto: 'Roboto', sans-serif;
$roboto-medium: 'Roboto-Medium', sans-serif;
