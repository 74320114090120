@import 'base/base';
@import 'utilities/vars';
@import 'utilities/mixins';

@import 'layout/spec';
@import 'modules/spec';



// hard styles 
h1.spec-demo-h1 {
	color: $white;
	font-family: $roboto;
	font-size: 4.8rem;
	display: block;
	width: 100%;
	text-align: center;
	margin-top: 50px;
}
h2.spec-demo-h2 {
	color: $white;
	font-family: $roboto;
	font-size: 3.6rem;
	position: relative;
	display: block;
	width: 100%;
	height: 5rem;
	line-height: 5rem;
	text-align: right;
	padding-right: 2rem;
	&:before {
		content: '';
		position: absolute;
		display: block;
		background-color: $white;
		height: 100%;
		width: 1rem;
	}
}
h3.spec-demo-h3 {
	color: $white;
	font-family: $pt-sans;
	font-size: 1.6rem;
	font-weight: bold;
	position: relative;
	display: block;
	width: 100%;
	height: 2rem;
	text-align: right;
	padding-right: 2rem;
}

// SPEC-NAVIGATION
.nav-spec {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
	&-toggle {
		position: absolute;
		display: inline-block;
		font-family: $roboto;
		font-size: 1.4rem;
		font-weight: 600;
		color: $dark;
		right: 20px;
		top: 20px;
		cursor: pointer;
		letter-spacing: 1px;
		-webkit-font-smoothing: auto;

		h4 {
			display: inline-block;
		    float: left;
		    line-height: 2.4rem;
			margin-right: 10px;
		}
	}
	&-menu {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		transform: translateY(0);
		@include transition(transform, 350ms, ease-in-out);
		background-color: $dark;
		ul {
			display: inline-block;
			float: left;
			margin: 5rem;
			li {
				margin-bottom: 1rem;
				&:first-child { margin-bottom: 1.8rem; }
				a {
					text-decoration: none;
					h1, h5 { @include transition(color, .3s, ease) }
					&:hover { h1, h5 {color: $extra-cyan;} }
				}
			}
		}


		&-close {
			color: $white;
			text-decoration: none;
			font-family: $roboto;
			font-size: 30px;
			line-height: .6;
			position: absolute;
			right: 20px;
			top: 20px;
		}
		&.hidden { transform: translateY(-100%); }
	}
}
// SPEC-NAVIGATION END
// COLORS DEMO
.spec_colors-demo {
	position: relative;
	display: inline-block;
	width: 1020px;
	&-column {
		display: inline-block;
		float: left;
		width: 204px;
		.color-preview {
			display: inline-block;
			width: 100%;
			height: 48px;
			margin-bottom: 31px;
			&-circle {
				display: inline-block;
				float: left;
				width:48px;
				height: 48px;
				border-radius: 50%;
				margin-right: 12px;
				&.cp-dark { background-color: $dark; }
				&.cp-dark-plus { background-color: $dark-plus; }
				&.cp-grey { background-color: $grey; }
				&.cp-grey-plus { background-color: $grey-plus; }
				&.cp-grey-second { background-color: $grey-second; }
				&.cp-light { background-color: $light; }
				&.cp-white { background-color: $white; }

				&.cp-blue { background-color: $blue; }
				&.cp-green { background-color: $green; }
				&.cp-yellow { background-color: $yellow; }
				&.cp-orange { background-color: $orange; }
				&.cp-pink { background-color: $pink; }
				&.cp-purple { background-color: $purple; }
				&.cp-red { background-color: $red; }

				&.cp-blue-light { background-color: $blue-light; }
				&.cp-green-light { background-color: $green-light; }
				&.cp-orange-light { background-color: $orange-light; }
				&.cp-pink-light { background-color: $pink-light; }
				&.cp-purple-light { background-color: $purple-light; }
				&.cp-red-light { background-color: $red-light; }

				&.cp-grey-text { background-color: $grey-text; }
				&.cp-grey-text2 { background-color: $grey-text2; }
				&.cp-text-second { background-color: $text-second; }

				&.cp-extra-cyan { background-color: $extra-cyan; }
				&.cp-extra-lime { background-color: $extra-lime; }
				&.cp-extra-yellow { background-color: $extra-yellow; }
				&.cp-extra-coral { background-color: $extra-coral; }
				&.cp-extra-green { background-color: $extra-green; }
				&.cp-extra-blue { background-color: $extra-blue; }
				&.cp-extra-purple { background-color: $extra-purple; }
			}
			&-hex {
				font-family: $pt-sans;
				font-size: 1.5rem;
				padding-top: .5rem;
				color: #7c8999;
			}
			&-var {
				font-family: $pt-sans;
				font-size: 1.3rem;
				color: $text-second;
				padding-top: 6px;
			}
		}
	}
}

// ICONS DEMO
.spec-icons {
	position: relative;
	display: inline-block;
	&-li-fl {
		display: inline-block;
		float: left;
		margin-right: 3rem;
		&-n {
			display: inline-block;
			float: none;
			margin-bottom: 1.2rem;
			svg {cursor: pointer;}
		}
	}
	&-16 ul { 
		width: 16px;
		li {
			width: 16px;
			height: 16px;
		}
	}
	&-24 ul { 
		width: 24px;
		li {
			width: 24px;
			height: 24px;
		}
	}
	&-32 ul { 
		width: 32px;
		li {
			width: 32px;
			height: 32px;
		}
	}
	&-50 ul { 
		width: 50px;
		li {
			width: 50px;
			height: 50px;
		}
	}
	&-60 ul { 
		width: 60px;
		li {
			width: 60px;
			height: 60px;
		}
	}
}
// ICONS DEMO END


// BUTTONS DEMO

// TECHNICAL STYLES FOR BUTTONS PREVIEW

.buttons {
	display: inline-block;
	margin-bottom: 50px;
	.buttons-column {
		display: inline-block;
		float: left;
		width: 140px;
		&-sizes {
			display: inline-block;
			float: left;
			width: 140px;
		}
		&-unit-center { 
			margin-bottom: 3.6rem; 
			p {
				font-family: $pt-sans;
				font-size: 1.5rem;
				padding-bottom: 1rem;
				color: #7c8999;
				&.sm {
					font-size: 1.2rem;
				}
			}
		}

		// DEMO BUTTONS
		button.button-demo, a.button-demo {

			&-blue {
				color: $white;
				&-hover{ 
					color: $white;
					background-color: lighten($blue, 10%); 
				}
				&-click{
					color: $white;
					background-color: darken($blue, 10%); 
				}
				&-disabled{
					cursor: default;
					opacity: .7;
					color: $white;
					background-color: $blue;
				}

				&-bordered {
					&-hover{
						color: $white;
						background-color: $blue;
					}
					&-click{
						color: $white;
						background-color: darken($blue, 10%);
					}
					&-disabled {
						cursor: default;
						opacity: .7;
						color: $white;
						background-color: $blue;
					}
				}
			}

			&-green {
				&-hover{ 
					color: $white;
					background-color: lighten($green, 10%);
				}
				&-click{
					color: $white;
					background-color: darken($green, 10%);
				}
				&-disabled{
					cursor: default;
					opacity: .7;
					color: $white;
					background-color: $green;
				}
			}

			&-pink {
				&-hover{ 
					color: $white;
					background-color: lighten($pink, 10%); 
				}
				&-click{
					color: $white;
					background-color: darken($pink, 10%); 
				}
				&-disabled{
					cursor: default;
					opacity: .7;
					color: $white;
					background-color: $pink;
				}
			}

			&-yellow {
				&-hover{ 
					color: $dark;
					background-color: lighten($yellow, 10%);
				}
				&-click{
					color: $dark;
					background-color: darken($yellow, 10%);
				}
				&-disabled{
					cursor: default;
					opacity: .7;
					color: $dark;
					background-color: $yellow;
				}
			}

			&-grey {
				&-hover{ 
					color: $white;
					background-color: lighten($grey, 10%);
				}
				&-click{
					color: $white;
					background-color: darken($grey, 10%);
				}
				&-disabled{
					cursor: default;
					opacity: .7;
					color: $white;
					background-color: $grey;
				}
			}

			&-white {
				&-hover{ 
					color: $dark;
					background-color: lighten($grey-second, 10%);
				}
				&-click{
					color: $dark;
					background-color: darken($grey-second, 10%);
				}
				&-disabled{
					cursor: default;
					opacity: .7;
					color: $dark;
					background-color: $grey-second;
				}
			}
		}
	}
}
.demo-button-link {
	position: relative;
		text-decoration: none;
		font-family: $roboto;
		font-size: 1.4rem;
		padding: 0 .8rem;

		&.hover {
			&.green { color: lighten($green, 10%); }
			&.blue { color: lighten($blue, 10%); }
			&.white { color: lighten($white, 10%); }
            &.dark { color: lighten($dark, 10%); }
            
            &.brackets {
                font-size: 1.5rem;
                
                &:before {
                    content: '[';
                    position: absolute;
                    left: 0;
                    top: .1rem;
                }
                &:after {
                    content: ']';
                    position: absolute;
                    right: 0;
                    top: .1rem;
                }
            }
		}
		&.active {
			&.green { color: darken($green, 10%); }
			&.blue { color: darken($blue, 10%); }
			&.white { color: darken($white, 10%); }
            &.dark { color: darken($dark, 10%); }
            
            &.brackets {
                font-size: 1.5rem;
            }
		}

}

.spec-text-group { 
	margin-bottom: 3.2rem;
	* { margin-bottom: 1.2rem; }
}

.spec-demo-inputs {
	margin-bottom: 2.4rem;
}
.spec-label { 
	display: block;
	@extend .typo-ui-h5; 
	color: $grey-text;
	margin-bottom: 1.2rem;
}
.wysi-add-tool {
	position: relative;
	display: block;
	width: 338px;
	height: 50px;
	overflow: hidden;
	left: auto;
	top: 0;
}

.tooltips_container {
	position: relative;
	width: 100%;
	height: 200px;
	div:nth-child(1) {
		left: 125px;
		top: 44px;
	}
	div:nth-child(2) {
		left: 0;
		top: 0;
	}
	div:nth-child(3) {
		left: 125px;
		top: 0;
	}
	div:nth-child(4) {
		left: 0;
		top: 44px;
	}
}

.tooltips_test {
	position: relative;
	width: 100%;
	height: 200px;
	p:nth-child(1) {
		left: 0px;
		top: 94px;
	}
	p:nth-child(2) {
		left: 175px;
		top: 0;
	}
	p:nth-child(3) {
		left: 175px;
		top: 94px;
	}
	p:nth-child(4) {
		left: 0;
		top: 0;
	}
}

.tooltip_test {
	@extend .typo-ui-txt;
	color: $dark;
	position: absolute;
	cursor: pointer;
	opacity: 1;
	transform: translate(0);
}

.input-form-demo-wrap {
	min-height: 101px;
	width: 350px;
	float: left;
	padding: 12px 24px 24px;
	background: #fff;
	&.dark { background: $dark; }
	&.clear { clear: both; }
	&:last-of-type { margin-bottom: 32px; }
	&.button-demo {
		width: 700px;
		margin-top: 24px;
		padding: 24px;
		.input-form {margin: auto;}
	}
}