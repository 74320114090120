.rubrics {
	@extend .typo-ui-txt;
	border: 0px;
	margin-top: 6px;
	font-size: 14px;
	color: $dark;
	margin-bottom: 12px;
	background: $white;
}

.modal_overlay {
	position: absolute;
	display:block;
	background-color: transparentize($dark, .07);
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 9000;
	opacity: 1;
	cursor: pointer;
	@include transition(opacity .3s ease-out);
	&.hidden {
		@include transition(opacity .3s ease-out, top .1s ease-out .3s);
		opacity: 0;
		top: 100%;
	}
}
.settings_window {
	position: absolute;
	display: block;
	width: 936px;
	height: 682px;
	background-color: $white;
	z-index: 99077;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -51%);
	opacity: 1;
	@include transition(opacity .3s ease-out);
	&.hidden {
		@include transition(opacity .3s ease-out, transform .1s ease-out .3s);
		top: -100%;
	    left: -100%;
		opacity: 0;
	}
	&.error {
		#short_warning h3 {color: $red;}
	}
	.settings_headline {
		position: relative;
		display: block;
		width: 100%;
		height: 106px;
		h2 {
			position: relative;
			display: block;
			width: 100%;
			padding: 56px 41px 0;

		}
		.settings_close {
			width: 24px;
			height: 24px;
			display: block;
			position: absolute;
			top: 54px;
			right: 54px;
			cursor: pointer;
		}
	}
	.settings_left_block {
		margin-left: 40px;
		margin-right: 24px;
		display: block;
		float: left;
		position: relative;
		.droparea-btn {
			@extend .button-link;
			color: $dark;
			display: block;
			width: 249px;
			position: absolute;
			bottom: -18px;
			padding: 12px 0;
			left: 0;
			text-align: center;
			transition: color .2s;
			cursor: pointer;
		}
		&:hover { .droparea-btn {color: $blue;} }
		.droparea-div {
			margin-bottom: 18px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			height: 272px;
			width: 252px;
			.droparea-div-state-uploaded-hover{
				padding-top: 106px;
				transition: opacity 0.3s ease;
			}
			.droparea-div-preview, #droparea {
				height: 270px;
				width: 250px;
			}
			&.hover {
				border: 2px dashed #337ADE;
				.droparea-div-state-uploaded-hover {
					position: absolute;
					width: 100%;
					height: 100%;
					background-color: rgba(51,122,222,0.3);
					opacity: 1;
				}
			}
		}

		.droparea-div-state-normal {
			position: relative;
			top: -24px;
			&.loading {
				display: block;
				background-image: url(/img/loading.svg);
				background-position: center;
				background-repeat: no-repeat;
				background-color: rgba(36,44,47,0.93);
				height: 100%;
				opacity: 1;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
				svg { display: none; }
			}
		}
		.set_as_cover_check {
			display: inline-block;
    		padding-right: 24px;
			label { width: 220px; }
		}
	}
	.settings_right_block {
		display: block;
		float: left;
		width: 608px;
		height: 525px;
		padding-right: 96px;
		.settings_title {
			padding-bottom: 16px;
			.input {padding-right: 66px;}
		}
		.settings_rubrics {
			padding-bottom: 9px;
			margin-top: 57px;
			.settings_rubrics_field {
				&.error {
					.rubricator {
						border-color: $red;
						&>p {color: $red;}
						use {fill: $red;}
					}
				}
				.placeholder {top: -40px !important;}
			}

			#settings_rubrics_field {
				display: block;
				width: 100%;
				border-bottom: 2px solid $grey-second;
				span {
					@extend .typo-ui-txt;
					&:after {
						content: '';
						display: block;
						width: 16px;
						height: 16px;
						background: url('data:text/plain;charset=utf-8,%3Csvg%20width%3D%2210px%22%20height%3D%2210px%22%20viewBox%3D%220%200%2016%2016%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20xmlns%3Asketch%3D%22http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch%2Fns%22%3E%0D%0A%20%20%20%20%3Cpath%20d%3D%22M11.6329659%2C12.7654662%20L7.99976973%2C9.13307101%20L4.36737457%2C12.7654662%20C4.05419562%2C13.0786451%203.54718214%2C13.0786451%203.23480417%2C12.7654662%20C2.92242619%2C12.4522872%202.92242619%2C11.9452737%203.23480417%2C11.6328958%20L6.86719933%2C7.99969964%20L3.2340032%2C4.36730447%20C2.92162523%2C4.05412553%202.92162523%2C3.54711205%203.2340032%2C3.23473407%20C3.54718214%2C2.9223561%204.05419562%2C2.9223561%204.3665736%2C3.23473407%20L7.99976973%2C6.86712924%20L11.6321649%2C3.2339331%20C11.9453438%2C2.92155513%2012.4523573%2C2.92155513%2012.7647353%2C3.2339331%20C13.0779142%2C3.54631108%2013.0779142%2C4.05412553%2012.7647353%2C4.3665035%20L9.13234013%2C7.99969964%20L12.7647353%2C11.6320948%20C13.0779142%2C11.9452737%2013.0779142%2C12.4522872%2012.7655363%2C12.7654662%20C12.4531583%2C13.0786451%2011.9461448%2C13.0786451%2011.6329659%2C12.7654662%20Z%22%20id%3D%22Rectangle-272-Copy-2%22%20fill%3D%22%23AFC1D8%22%3E%3C%2Fpath%3E%0D%0A%3C%2Fsvg%3E');
					}
				}
			}

			#settings_open_rubricator {
				@extend .button-link;
				@extend .button-link.blue;
				margin-top: 12px;
				font-size: 1.3rem;
				display: block;
				width: 100%;
				text-align: right;
			}
		}
		.settings_description {
			.input {padding-right: 66px;}
		}

		.chars-left {
			position: absolute;
			right: 0;
			bottom: 11px;
			pointer-events: none;
			opacity: 0;
			transition: opacity 0.2s;

			&.visible { opacity: 1; }
		}

		.rubricator {
			position: relative;
			top: -36px;
			white-space: nowrap;
			border-bottom: 2px solid $grey-second;
			padding-bottom: 6px;
			.current_rubrics {
				display: inline-block;
				cursor: pointer;
				p {
					display: inline-block;
					white-space: nowrap;
					overflow: hidden;

					svg {
						position: relative;
						top: 3px;
						margin-right: 15px;
						margin-left: 6px;
						&:hover {use {fill: darken($grey, 10%);}}
					}
					&:first-child { color: $extra-green; }
				}
			}
			.success {
				cursor: pointer;
				display: inline-block;
				white-space: nowrap;
				overflow: hidden;
				width: 204px;
				transition: width 0.2s;
				&.hidden { display: none; }
				svg {
					position: relative;
					top: 4px;
				}
				&:after {
					content: 'Укажите рубрику';
					display: inline-block;
				}
				&.active {
					display: none;
					width: 140px;
					&:after {
						content: 'Добавить рубрику';
					}
				}
			}
			.rubrics_list {
				position: absolute;
				top: 24px;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				height: 240px;
				width: 100%;
				padding: 30px 24px;
				padding-bottom: 0;
				background: $light;
				z-index: 2;
				transition: all 0.3s;
				opacity: 0;
				pointer-events: none;
				&.active {
					opacity: 1;
					pointer-events: auto;
					top: 28px;
				}
				.category {
					width: 256px;
					margin-bottom: 1px;
				}
				label {cursor: pointer;}
			}
		}
	}
	.settings_clear {
		margin: 0;
		height: 1px;
		opacity: 0;
	}
	.settings_bottom_block {
		display: block;
		position: absolute;
		bottom: 0;
		width: 100%;
		min-height: 133px;
		padding-left: 42px;
		background-color: $light;
		#check_warning, #short_warning {
			margin-top: 42px;
			margin-bottom: 107px;
			display: none;
			transition: color .2s;
		}
		h3 {
			@extend .typo-ui-h3;
			color: $dark;
			font-weight: 500;
			margin-bottom: 12px;
		}
		p {
			@extend .typo-ui-txt;
			color: $dark;
			span {color: $green;}
		}
		.button {
			position: absolute;
			left: 42px;
			bottom: 54px;
			&.disabled {
				opacity: .7;
				pointer-events: none;
			}
		}
	}
}
