button {
	-webkit-appearance: none;
	-moz-appearance: none;
	outline: none;
	border: none;
	background-color: transparent;
	font-family: $roboto;
	font-weight: 500;
	border-radius: .3rem;
	@include transition(all, 150ms, ease);
	cursor: pointer;
	&:disabled {
		cursor: default;
		opacity: .7;
	}
}
a.button {
	display: inline-block;
	text-decoration: none;
	font-family: $roboto;
	font-weight: 500;
	border-radius: .3rem;
	@include transition(all, 150ms, ease);
	cursor: pointer;
}

.button {
	// SIZES
	&-small, &-middle { font-size: 1.4rem; }
	&-small { padding: .8rem 1.5rem; }
	&-middle { padding: 1.1rem 1.5rem; }
	&-big { font-size: 1.7rem; padding: 1.5rem 1.8rem; font-weight: normal !important; }
	// COLORS
	&-blue {
        @include buttons-states($white, $blue);
        &-bordered { @include buttons-states-bordered($white, $blue); }
    }
//	&-blue-bordered { @include buttons-states-bordered($white, $blue); }
	&-green {
		@include buttons-states($white, $green);
		&-bordered { @include buttons-states-bordered($white, $green); }
	}
	&-pink { @include buttons-states($white, $pink); }
	&-yellow { @include buttons-states($white, $yellow); }
	&-grey { @include buttons-states($dark, $grey-second); }
	&-white { @include buttons-states($dark, $grey-second); }
	&-extra-green-bordered { @include buttons-states-bordered($white, $extra-green); }

	// button-link styles
	&-link {
		position: relative;
		text-decoration: none;
		font-family: $roboto;
		font-size: 1.4rem;
		font-weight: 500;
		padding: 0 .8rem;
		@include transition(color, .3s, ease);
		&.green { color: $green; }
		&.blue { color: $blue; }
		&.white { color: $white; }
        &.dark { color: $dark; }

        &.brackets {
            font-size: 1.5rem;

            &:before {
                content: '[';
                opacity: 0;
                position: absolute;
                left: 0;
                top: .1rem;
            }
            &:after {
                content: ']';
                opacity: 0;
                position: absolute;
                right: 0;
                top: .1rem;
            }
        }

		&:before, &:after { @include transition(opacity, .3s, ease); }
		&:hover:not(:active):not(.disabled) {
			&:before, &:after { opacity: 1; }
			&.green { color: lighten($green, 5%); }
			&.blue { color: lighten($blue, 5%); }
			&.white { color: lighten($white, 5%); }
            &.dark { color: $blue; }
		}
		&:active:not(.disabled) {
			&.green { color: darken($green, 3%); }
			&.blue { color: darken($blue, 3%); }
			&.white { color: darken($white, 3%); }
            &.dark { color: darken($blue, 3%); }
		}
		&.disabled {
			opacity: .8;
			cursor: default;
		}
	}
}
//a.button.button-blue-bordered.button-middle {
//    background-color: transparent;
//    border: 2px solid $blue;
//    color: $blue;
//    padding: 0.8rem 1.3rem;
//    &:enabled {
//        background-color: transparent;
//        border: 2px solid $blue;
//        color: $blue;
//        &:hover {
//            background-color: $blue;
//            border-color: $blue;
//            color: $white;
//        }
//        &:active {
//            background-color: darken($blue, 10%);
//            border-color:  darken($blue, 10%);
//        }
//    }
//}
