.confirm {
	width: 100%;
	height: 36px;
	background-color: $orange-light;
	display: block; // указываем none на страницах, где не хотим показывать эту плашку
	transition: top .4s;
	text-align: center;
	overflow: hidden;
	position: fixed;
	top: 50px;
	z-index: 3;
	&.hidden { top: 10px; }
	
	p {
		@extend .typo-ui-txt;
		color: $dark;
		margin-right: 18px;
		display: inline-block;
		span { font-weight: 500; }
	}
	.button-link {
		margin-right: 18px;
		padding: 0;
		float: none;
		color: $dark;
		&:hover {color: $blue;}
	}
	.close-confirm {
		position: absolute;
		right: 24px;
		top: 10px;
		cursor: pointer;
		display: none;
		&:hover use { fill: $blue }
	}
}

.confirm-container {
	margin: auto;
	width: 100%;
	text-align: center;
	padding-top: 9px;
	position: absolute;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0s;
	&.active {
		position: relative;
		opacity: 1;
		pointer-events: auto;
		transition: opacity .3s;
	}
}

.resend {
	padding-top: 6px;
	svg {
		position: relative;
		top: 4px;
		margin-right: 12px;
	}
}

.set_overlay {
	background: $dark;
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 999;
	opacity: 0;
	transition: opacity .5s;
	pointer-events: none;
	&.active {
		pointer-events: auto;
		opacity: .95;
	}
}

.forms {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	form {
		opacity: 0;
		position: absolute;
		pointer-events: none;
		transition: opacity 0s;
		&.active {
			opacity: 1;
			position: static;
			pointer-events: auto;
			transition: opacity .5s;
		}
	}
}

.form-email{
	.title {
		@extend .typo-ui-h3;
		color: #fff;
		margin-bottom: 24px;
	}
}

.form-set-success {
	width: 390px !important;
	text-align: center;
	
	.title {
		@extend .typo-ui-h3;
		color: #fff;
		margin-top: 16px;
		margin-bottom: 12px;
	}
	p {
		@extend .typo-ui-txt;
		color: $grey-text;
	}
}

.close-overlay {
	position: absolute;
	right: 60px;
	top: 60px;
	cursor: pointer;
}

@media all and (max-width: 767px) {
	.confirm { display: none !important; }
}