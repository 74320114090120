.tooltip_target { position: relative; }

.tool_wrap {
	position: absolute;
}

.tooltip {
	@extend .typo-ui-txt-small;
	color: #fff;
	height: auto !important;
	width: auto !important;
	background: $dark;
	border-radius: 3px;
	padding: 6px 12px;
	text-align: center;
	text-transform: none;
	pointer-events: none;
	position: absolute;
	white-space: nowrap;
	opacity: 0;
	transition: transform .3s, opacity .3s;
	&:before {
		content: "";
		display: block;
		height: 10px;
		width: 10px;
		background: $dark;
		position: absolute;
		transform: rotate(45deg);
	}
	&.right {
		transform: translateX(10px);
		&:before {
			left: -5px;
			top: 9px;
		}
	}
	&.left {
		transform: translateX(-10px);
		&:before {
			right: -5px;
			top: 9px;
		}
	}
	&.top {
		transform: translateY(-10px);
		&:before {
			right: calc(50% - 6px);
			bottom: -5px;
		}
	}
	&.bottom {
		transform: translateY(10px);
		&:before {
			right: calc(50% - 6px);
			top: -5px;
		}
	}
	&.visible {
		transform: translate(0);
		opacity: 1;
	}
}
