.i {
	&16 { @include square-size(1.6rem); }
	&24 {
		@include square-size(2.4rem);
		&.i24_base_star {
			fill: $blue;
			&:hover {fill: darken($blue, 10%)}
			&.grey {
				fill: $grey;
				&:hover { fill: $blue; }
			}
		}
		&.i24_base_menu {
			fill: $dark;
			&:hover, &.active { fill: $blue; }
		}
	}
	&32 { @include square-size(3.2rem); }
	&50 { @include square-size(5rem); }
	&60 { @include square-size(6rem); }
}

use {
	@include transition-property(fill, opacity);
	@include transition-duration(.2s);
	@include transition-timing-function(ease-in-out);
}

.ig use { fill: $grey; }
.ig use.i-act { opacity: 0; }
.ig.active use { fill: $blue;
	&.i-norm { opacity: 0; }
	&.i-act { opacity: 1; }
}
.ib use { fill: $blue; }
.igr use { fill: $green; }
.iy use { fill: $yellow; }
.io use { fill: $orange;}
.ip use { fill: $pink; }
.id use { fill: $dark; }
.iw use { fill: $white;
	&.i-act { opacity: 0; }
}
.iw.active use { fill: $blue; }
.iec use { fill: $extra-coral; }
.iegr use { fill: $extra-green; }

.ig:hover:not(.i16_base_money) use,
.iw:hover:not(.i32_base_reward_readers_choise):not(.i32_base_reward_popular) use { fill: $blue; }
.iw.active use { fill: $blue;
	&.i-norm { opacity: 0; }
	&.i-act { opacity: 1; }
}

#i16_base_eye_open g path:last-child {fill: inherit;}

svg path, svg g { fill: inherit; }
.i32_base_reward_editor use { fill: $white; }
.i32_base_reward_editors_choise use { fill: $yellow; }
.i32_base_reward_editors_choise.inactive use { fill: $grey; }
.i32_base_reward_editors_choise.inactive:hover use { fill: $yellow; }
.i32_base_reward_readers_choise use { fill: $extra-green; }
.i32_base_reward_readers_choise.inactive use { fill: $grey; }
.i32_base_reward_readers_choise.inactive:hover use { fill: $extra-green; }
.i32_base_reward_popular use { fill: $extra-coral; }
.i32_base_reward_popular.inactive use { fill: $grey; }
.i32_base_reward_popular.inactive:hover use { fill: $extra-coral; }
.i32_base_reward_recommend use { fill: $green; }
.i32_base_reward_recommend.inactive use { fill: $grey; }
.i32_base_reward_recommend.inactive:hover use { fill: $green; }
.i32_base_like use, .i32_base_like_full use, .i50_base_like use {
	fill: $extra-coral;
	&.i-act { opacity: 0;}
	&:hover {
		&.i-act { opacity: 1; }
		&.i-norm { opacity: 0; }
	}
}

.i32_base_favorite {
	&:hover { use {fill: $extra-green !important;} }
	.i-act { fill: $extra-green !important; }
}
.i32_base_like.active {
	@include keyframes(like32) {
		0% {
			@include square-size(3.2rem);
			margin: 15px 0;
		}
		50% {
			@include square-size(3rem);
			margin: 15px 1px;
		}
		100% {
			@include square-size(3.2rem);
			margin: 15px 0;
		}
	}
	@include animation(like32 .3s ease-in-out);
	use {
		&.i-act { opacity: 1; }
		&.i-norm { opacity: 0; }
	}
}
.i50_base_like.active {
	@include keyframes(like) {
		0% {
			@include square-size(5rem);
			margin: 0;
		}
		50% {
			@include square-size(4.8rem);
			margin: .1rem .1rem;
		}
		100% {
			@include square-size(5rem);
			margin: 0;
		}
	}
	@include animation(like .3s ease-in-out);
	use {
		&.i-act { opacity: 1; }
		&.i-norm { opacity: 0; }
	}
}
.ui_photo use, .ui_video use { fill: $grey; }
