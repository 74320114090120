%colors {
	&.dark { color: $dark; }
	&.dark-plus { color: $dark-plus; }
	&.grey-text { color: $grey-text;}
	&.grey-text2 { color: $grey-text2;}
	&.text-second { color: $text-second;}
	&.blue { color: $blue; }
	&.grey { color: $grey; }
	&.green, &.success { color: $green; }
	&.white { color: $white; }
	&.green-light { color: $green-light; }
	&.yellow { color: $yellow; }
	&.pink, &.error { color: $pink; }
	&.purple { color: $purple; }
	&.red { color: $red; }
}

.typo {
    &-menu {
        &-h1 {
            font-family: $roboto;
			font-weight: 600;
			font-size: 30px;
			letter-spacing: .2px;
            line-height: 36px;
			@extend %colors;
        }
    }

	&-ui {
		&-h1 {
			font-family: $roboto;
			font-weight: 500;
			font-size: 2.4rem;
			letter-spacing: 0;
			line-height: 26px;
			@extend %colors;
		}
		&-h2 {
			font-family: $roboto;
			font-weight: 600;
			font-size: 1.8rem;
			letter-spacing: 1px;
			@extend %colors;
		}
		&-h3 {
			font-family: $roboto;
			font-size: 1.8rem;
			letter-spacing: 0;
			line-height: 21px;
			@extend %colors;
		}
		&-h4 {
			font-family: $roboto;
			font-weight: 600;
			font-size: 1.4rem;
			letter-spacing: 1px;
			@extend %colors;
		}
		&-h5 {
			font-family: $roboto;
			font-weight: 600;
			font-size: 1.2rem;
			letter-spacing: .7px;
			@extend %colors;
		}
		&-txt {
			font-family: $roboto;
			font-size: 1.4rem;
			letter-spacing: 0;
			line-height: 17px;
			@extend %colors;
			&-medium {
				font-family: $roboto;
				font-size: 1.4rem;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 17px;
				@extend %colors;
			}
			&-small {
				font-family: $roboto;
				font-size: 1.3rem;
				letter-spacing: 0;
				line-height: 16px;
				@extend %colors;
			}
			&.link, &-small.link, &-medium.link {
                font-family: $pt-sans;
				color: $blue;
				text-decoration: none;
				&:hover { color: lighten($blue, 10%); }
				&:active { color: darken($blue, 10%); }
			}

            &-link {
                font-size: 1.5rem;
            }

            &-small-link {
                font-size: 1.3rem;
            }
		}
	}
	&-feed {
		&-h1 {
			font-family: $pt-sans;
			font-size: 2.6rem;
			line-height: 28px;
			font-weight: 600;
			@extend %colors;
		}
		&-p {
			font-family: $pt-sans;
			font-size: 1.7rem;
			line-height: 26px;
			@extend %colors;
		}
	}
	&-rb {
		&-h1 {
			font-family: $roboto;
			font-weight: 600;
			font-size: 2.4rem;
			letter-spacing: .2px;
			@extend %colors;
		}
		&-h2 {
			font-family: $roboto;
			font-weight: 500;
			font-size: 1.5rem;
			letter-spacing: 0.1px;
			line-height: 20px;
			@extend %colors;
		}
	}
	&-art {
		&-h1 {
			font-family: $pt-serif;
			font-size: 4.8rem;
			line-height: 56px;
			@extend %colors;
		}
		&-h2 {
			font-family: $pt-sans;
			font-size: 2.6rem;
			line-height: 32px;
			@extend %colors;
		}
		&-h3 {
			font-family: $pt-sans;
			font-size: 2rem;
			line-height: 28px;
			@extend %colors;
		}
		&-p {
			font-family: $pt-sans;
			font-size: 1.7rem;
			line-height: 26px;
			@extend %colors;
		}
		&-quote {
			font-family: $pt-sans;
			font-style: italic;
			font-size: 1.7rem;
			line-height: 26px;
			@extend %colors;
			&-author {
				font-family: $pt-sans;
				font-style: italic;
				font-size: 1.6rem;
				line-height: 20px;
				@extend %colors;
			}
		}
	}
}
