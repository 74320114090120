// TEXT MIXINS
@mixin text-default {
	font-family: $roboto;
	font-size: 1.6rem;
	color: $black;
}
@mixin text-big($color) {
	font-family: $pt-serif;
	font-size: 2.5rem;
	color: $color;
}

// VENDORS
@mixin transition($transition...) {
	-moz-transition:    $transition;
	-o-transition:      $transition;
	-webkit-transition: $transition;
	transition:         $transition;
}
@mixin transition-property($property...) {
	-moz-transition-property:    $property;
	-o-transition-property:      $property;
	-webkit-transition-property: $property;
	transition-property:         $property;
}
@mixin transition-duration($duration...) {
	-moz-transition-duration:    $duration;
	-o-transition-duration:      $duration;
	-webkit-transition-duration: $duration;
	transition-duration:         $duration;
}
@mixin transition-timing-function($timing...) {
	-moz-transition-timing-function:    $timing;
	-o-transition-timing-function:      $timing;
	-webkit-transition-timing-function: $timing;
	transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
	-moz-transition-delay:    $delay;
	-o-transition-delay:      $delay;
	-webkit-transition-delay: $delay;
	transition-delay:         $delay;
}

@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@-ms-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
}

@mixin animation($str) {
	-webkit-animation: #{$str};
	-moz-animation: #{$str};
	-ms-animation: #{$str};
	-o-animation: #{$str};
	animation: #{$str};
}

// BUTTON STYLES
@mixin buttons-states($color, $background) {
	background-color: $background;
	color: $color;
	&:enabled {
		&:hover { background-color: lighten($background, 5%); }
		&:active { background-color: darken($background, 3%); }
	}
}

@mixin buttons-states-grey($color, $background) {
 background-color: $background;
 color: $color;
 &:enabled {
  &:hover { background-color: #9BABBF; }
  &:active { background-color: #9BABBF; }
 }
}

@mixin buttons-states-white($color, $background) {
 background-color: $background;
 color: $color;
 &:enabled {
  &:hover { background-color: #CED6E0; }
  &:active { background-color: #CED6E0; }
 }
}

@mixin buttons-states-bordered($color, $background) {
	background-color: transparent;
    border: 2px solid $background;
	color: $background;
	padding: 0.9rem 1.3rem;
	&:enabled {
		background-color: transparent;
		border: 2px solid $background;
		color: $background;
		&:hover {
			background-color: $background;
			border-color: $background;
			color: $color;
		}
		&:active {
			background-color: darken($background, 10%);
			border-color:  darken($background, 3%);
		}
	}
	&:hover {
		background-color: $background;
		border-color: $background;
		color: $color;
	}
	&:active { 
		background-color: darken($background, 10%);
		border-color:  darken($background, 3%);
	}
}
@mixin square-size ($size) {
	width: $size;
	height: $size;
}
