@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700|PT+Sans:400,400italic,700,700italic|PT+Serif:400,700,400italic,700italic&subset=latin,cyrillic);
/*
@font-face {
    font-family: 'pt_sansbold';
    src: url('../fonts/pt_sans-web-bold-webfont.eot');
    src: url('../fonts/pt_sans-web-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pt_sans-web-bold-webfont.woff2') format('woff2'),
         url('../fonts/pt_sans-web-bold-webfont.woff') format('woff'),
         url('../fonts/pt_sans-web-bold-webfont.ttf') format('truetype'),
         url('../fonts/pt_sans-web-bold-webfont.svg#pt_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'pt_sansitalic';
    src: url('../fonts/pt_sans-web-italic-webfont.eot');
    src: url('../fonts/pt_sans-web-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pt_sans-web-italic-webfont.woff2') format('woff2'),
         url('../fonts/pt_sans-web-italic-webfont.woff') format('woff'),
         url('../fonts/pt_sans-web-italic-webfont.ttf') format('truetype'),
         url('../fonts/pt_sans-web-italic-webfont.svg#pt_sansitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'pt_sansregular';
    src: url('../fonts/pt_sans-web-regular-webfont.eot');
    src: url('../fonts/pt_sans-web-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pt_sans-web-regular-webfont.woff2') format('woff2'),
         url('../fonts/pt_sans-web-regular-webfont.woff') format('woff'),
         url('../fonts/pt_sans-web-regular-webfont.ttf') format('truetype'),
         url('../fonts/pt_sans-web-regular-webfont.svg#pt_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'pt_serifbold';
    src: url('../fonts/pt_serif-web-bold-webfont.eot');
    src: url('../fonts/pt_serif-web-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pt_serif-web-bold-webfont.woff2') format('woff2'),
         url('../fonts/pt_serif-web-bold-webfont.woff') format('woff'),
         url('../fonts/pt_serif-web-bold-webfont.ttf') format('truetype'),
         url('../fonts/pt_serif-web-bold-webfont.svg#pt_serifbold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'pt_serifitalic';
    src: url('../fonts/pt_serif-web-italic-webfont.eot');
    src: url('../fonts/pt_serif-web-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pt_serif-web-italic-webfont.woff2') format('woff2'),
         url('../fonts/pt_serif-web-italic-webfont.woff') format('woff'),
         url('../fonts/pt_serif-web-italic-webfont.ttf') format('truetype'),
         url('../fonts/pt_serif-web-italic-webfont.svg#pt_serifitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'pt_serifregular';
    src: url('../fonts/pt_serif-web-regular-webfont.eot');
    src: url('../fonts/pt_serif-web-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pt_serif-web-regular-webfont.woff2') format('woff2'),
         url('../fonts/pt_serif-web-regular-webfont.woff') format('woff'),
         url('../fonts/pt_serif-web-regular-webfont.ttf') format('truetype'),
         url('../fonts/pt_serif-web-regular-webfont.svg#pt_serifregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'robotobold';
    src: url('../fonts/roboto-bold-webfont.eot');
    src: url('../fonts/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-bold-webfont.woff2') format('woff2'),
         url('../fonts/roboto-bold-webfont.woff') format('woff'),
         url('../fonts/roboto-bold-webfont.ttf') format('truetype'),
         url('../fonts/roboto-bold-webfont.svg#robotobold') format('svg');
    font-weight: 400;
    font-style: normal;

}




@font-face {
    font-family: 'robotomedium';
    src: url('../fonts/roboto-medium-webfont.eot');
    src: url('../fonts/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-medium-webfont.woff2') format('woff2'),
         url('../fonts/roboto-medium-webfont.woff') format('woff'),
         url('../fonts/roboto-medium-webfont.ttf') format('truetype'),
         url('../fonts/roboto-medium-webfont.svg#robotomedium') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'robotoregular';
    src: url('../fonts/roboto-regular-webfont.eot');
    src: url('../fonts/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('../fonts/roboto-regular-webfont.woff') format('woff'),
         url('../fonts/roboto-regular-webfont.ttf') format('truetype'),
         url('../fonts/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
*/