@import 'susy/susy';
$susy: (
	container: auto,
  	math: fluid,
	columns: 12,
	gutters: 0,
	output: flexbox,
	gutter-position: inside,
	align: stretch,

	debug: (
		image: hide,
		color: rgba(#66f, .25),
	    output: background,
	    toggle: top right,
    )
);

.container-full {
	// position: relative;
	// display: inline-block;
	// float: left;
	// width: 100%;
	 min-width: 1280px;
	// height: auto;
	background-color: $blue;
	@include container(12);

}
.spec {
	&_left-lane {
		display: inline-block;
		float: left;
		width: 20%;
		min-height:200px;
		background-color: $blue;
		@include span(3);
	}
	&_right-lane {
		display: inline-block;
		float: left;
		width: 80%;
		min-height: 20rem;
		background-color: #E8EBED;
		padding-left: 8rem;
		padding-top: 9.5rem;
		@include span(9 nest);
		&.no-padding-top { padding-top: 0; }
		.right-first { @include span(3); }
		.right-second {
			@include span(2);
			background-color: $dark;
			padding-left: 1.8rem;
			min-height: 200px;
		}
		.col-4 { @include span(3); }
		.col-3 { @include span(4); }
		.col-2 { @include span(6); }
	}
}
