.wysi-add-tool {
	position: absolute;
	display: block;
	width: 50px;
	height: 50px;
	overflow: hidden;
	left: -67px;
	//top: -50px;
	margin-bottom: 54px;
	z-index: 0;

	@include transition-property(top, opacity, width);
	@include transition-duration(.3s);
	@include transition-timing-function(ease);
	&-btn {
		position: absolute;
		display: inline-block;
		float: left;
		cursor: pointer;
		background-color: #FFF;
		width: 5.5rem;
		svg {
			transform: rotate(0deg);
			@include transition(transform, .6s, ease-in-out);
			&.active { transform: rotate(135deg); }
		}
	}
	&.active {
		//width: 338px;
		width:215px!important;
		z-index: 1;

	}
	&.invisible { opacity: 0; }
	&-start-writing {
		display: block;
		height: 50px;
		line-height: 50px;
		float: left;
		padding-left: 67px;
		color: $grey;
		font-family: $pt-sans;
		font-size: 1.8rem;
		-webkit-appearance: none;
		-moz-appearance: none;
		border: none;
		background: none;
		&::-webkit-input-placeholder { color: $grey; }
		&:-ms-input-placeholder { color: $grey; }
		&:-moz-placeholder { color: $grey; }
		&::-moz-placeholder { color: $grey; }
	}
	&.sw {
		// width: 340px;
		width: 290px;
		.wysi-add-tool-start-writing {
			display: block;
		}
	}
	&-tools {
		position: absolute;
		display: inline-block;
		float: left;
		width: 146px;
		height: 5rem;
		background-color: $dark;
		border-radius: .4rem;
		left: -22.1rem;
		@include transition(left, .4s, ease);
		&:before {
			content: '';
			position: absolute;
			display: inline-block;
			width: 1.2rem;
			height: 1.2rem;
			background-color: $dark;
			transform: rotate(45deg);
			top: 2rem;
			left: -.6rem;
		}
		ul {
			position: relative;
			display: inline-block;
			float: left;
			width: 130px;
			height: 5rem;
			margin-left: 1.5rem;
			li {
				position: relative;
				width: 40px;
				height: 50px;
				display: inline-block;
				float: left;
				cursor: pointer;
				&#insert_table,
				&#insert_embed,
				&#insert_quiz {
					display: none; // Не сделаны
				}
				svg {
					position: absolute;
					display: block;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					margin: auto;
				}
				&:hover > svg use { fill: $blue; }
			}
		}
		&.active { left: 6.8rem; }
	}
}
